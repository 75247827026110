import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';

const state = {
    outbounds: [],
    current_commission: null,
    current_commission_source: null,
    active_storage_unit: null,
    current: {
        outbound_id: null,
        outbound: {},
        order_number: null,
        delivery_note_number: null,
        custom_field_1: null,
        client: {
            allow_direct_load: null,
        },
        storage_units: []
    }
};

const getters = {
    getField,
    articleToMove: (state) => {
        if(state.current_commission && state.current_commission_source){
            return state.current_commission.articles.filter(item => item.storage_unit_id == state.current_commission_source.id);
        }
    },
    batchesToPick: (state) => {
        if(state.current_commission && state.current_commission_source){
            let articles =  state.current_commission.articles.filter(item => item.storage_unit_id == state.current_commission_source.id);
            let batches = [];
            articles.forEach(function (item) {
                if(item.batch){
                    batches.push({
                        value: item.batch,
                        validated: false
                    });
                }
            })
            return batches;
        }
    },
    allSourcesCleared: (state) => {
        if(state.current_commission){
            if(state.current_commission.sources){
                const unscannedSources = state.current_commission.sources.filter(item => item.commission_used_as_source === false);
                if(unscannedSources.length === 0){
                    return true;
                }
            }
        }
        return false;
    },
    allowAlternativies(state){
        // NOT WORKING IN COMMISSION
        //return false;

        if(state.current){
            if(state.current.client){
                if(state.current.client.allow_alternatives == "1" ||state.current.client.allow_alternatives === true){
                    return true;
                }
            }
        }
        return false;
    },
    relableSources(state){
        if(state.current){
            if(state.current.client){
                if(state.current.client.commission_keep_changed == "0" ||state.current.client.commission_keep_changed === false){
                    return true;
                }
            }
        }
        return false;
    },
    loaded: (state) => {
        if(state.current){
            if(state.current.outbound){
                if(state.current.outbound.id !== null){
                    return true;
                }
            }
        }
        return false;
    }
};

const actions = {
    addCommission({commit, state}, payload){

        const request = {
            action: "move_article",
            outbound_id: state.current.outbound_id,
            ...payload,
        };

        // CREATE COMMISSION AND ADD RETURNING STORAGE UNIT TO ORDER
        return Vue.axios.put("outbound",request).then(function (response) {
            commit('addStorageUnitToOutbound',response.data.storage_unit);
        })

    },
    createOutbound({commit, state}){
        // CHECK IF OUTBOUND EXISTS
        if(state.current.order_number === null){
            const request = {
                action: 'create_outbound',
                client_id: state.current.client.id,
                delivery_note_number: state.current.delivery_note_number,
                custom_field_1: state.current.custom_field_1
            };

            return Vue.axios.post("outbound",request).then(function (response) {
                commit('updateOutboundId',response.data.id);
                commit('updateOrderNumber',response.data.order_number);
                commit('setClient',response.data.client);
            });
        }
    },
    removeStorageUnitFromOutbound({commit}, payload){
        const request = {
            action: 'remove_storage_unit',
            storage_unit: payload,
        };
        return Vue.axios.put("outbound",request).then(function () {
            commit('removeStorageUnitFromOutbound',payload);
        })

    },
    addStorageUnitToOutbound({commit,dispatch, state}, payload){
        dispatch('createOutbound').then(function() {
            const request = {
                action: 'add_storage_unit',
                outbound_id: state.current.outbound_id,
                storage_unit: payload,
            };

            return Vue.axios.put("outbound",request).then(function (response) {
                commit('addStorageUnitToOutbound',response.data.storage_unit);
            });

        });
    },
    updateOutboundMeta({state}){
        const request = {
            action: 'outbound_meta',
            delivery_note_number: state.current.delivery_note_number,
            custom_field_1: state.current.custom_field_1,
            outbound_id: state.current.outbound_id,
        };
        return Vue.axios.put("outbound",request);
    },
    revert({commit, state}){
        const request = {
            action: 'outbound_revert',
            outbound_id: state.current.outbound_id,
        };
        return Vue.axios.put("outbound",request).then(function () {
            commit('reset');
        });
    },
    create({commit,state,rootState}){
        const request = {
            action: 'outbound_provision',
            outbound_id: state.current.outbound_id,
            gate_id: rootState.gate.id
        };
        return Vue.axios.put("outbound",request).then(function () {
            commit('reset');
        });
    },
    load({state,rootState}){
        const request = {
            action: 'outbound_load',
            outbound_id: state.current.outbound_id,
            gate_id: rootState.gate.id
        };
        return Vue.axios.put("outbound",request);
    },
    updateCurrent({commit}, payload){
        commit('updateCurrent',payload);
    },
    updateCurrentCommission({commit}, payload){
        commit('updateCurrentCommission',payload);
    },
    updateCurrentCommissionSource({commit}, payload){
        commit('updateCurrentCommissionSource',payload);
    },
    provisionActiveStorageUnit({state,commit,rootState}){
        const request = {
            id: state.active_storage_unit.id,
            outbound_id: state.current.outbound.id,
            action: "provision_gate",
            gate_id: rootState.gate.id,
        };

        return Vue.axios.put("storage-unit", request).then(function () {
            commit('updateActiveStorageUnitState',"provisioned");
        });
    },
    setEuroPalletsUsed({state},payload){
        const request = {
            outbound_id: state.current.outbound.id,
            action: "set_euro_pallets_used",
            euro_pallets_used: payload,
        };
        return Vue.axios.put("outbound",request);
    },
    loadActiveStorageUnit({state,commit,rootState}){
        const request = {
            id: state.active_storage_unit.id,
            outbound_id: state.current.outbound.id,
            action: "load",
            gate_id: rootState.gate.id,
        };

        return Vue.axios.put("storage-unit",request).then(function () {
            commit('updateActiveStorageUnitState',"moved_out");
        });
    },
    setActiveStorageUnit({commit},payload){
        commit('setActiveStorageUnit',payload);
    },
    setAsCommissionSource({state,commit}){
        const request = {
            id: state.current_commission_source.id,
            outbound_id: state.current.outbound.id,
            commission_target: state.current_commission.target.id,
            action: 'comission_add_source'
        };

        return Vue.intranet.put(`storage-unit/${state.current_commission_source.id}`, request)
            .then(function () {
                commit('setAsCommissionSource');
            });
    },
    finishCurrentTarget({state}){

        const request = {
            id: state.current_commission.target.id,
            outbound_id: state.current.outbound.id,
            action: 'comission_finish_target'
        };

        return Vue.intranet.put(`storage-unit/${state.current_commission.target.id}`, request);
    },
    useAsAlternative({state},payload){

        const request = {
            id: payload.nve,
            outbound_id: state.current.outbound.id,
            action: 'commission_use_as_alterantive'
        };

        return Vue.axios.put('storage-unit', request);
    }
};

const mutations = {
    updateField,
    setAsCommissionSource(state){
        state.current_commission_source.commission_used_as_source = true;
        state.current_commission_source.state = "commission_used_as_source";
    },
    updateOutboundId(state,payload){
        state.current.outbound_id = payload;
    },
    updateOrderNumber(state,payload){
        state.current.order_number = payload;
    },
    setClient(state,payload){
        state.current.client = payload;
    },
    removeStorageUnitFromOutbound(state,payload){
        let storageUnit = state.current.storage_units.filter(item => item.id !== payload.id);
        Vue.set(state.current, 'storage_units', storageUnit);
    },
    addStorageUnitToOutbound(state,payload){
        state.current.storage_units.push(payload);
    },
    updateCurrent(state,payload){
        Vue.set(state,'current',payload);
    },
    updateCurrentCommission(state,payload){
        Vue.set(state,'current_commission',payload);
    },
    updateCurrentCommissionSource(state,payload){
        Vue.set(state,'current_commission_source',payload);
    },
    updateState(payload){
        Vue.set(state.current,'state',payload);
    },
    updateActiveStorageUnitState(state,payload){
        Vue.set(state.active_storage_unit,'state',payload);
    },
    updateActiveStorageUnitStatus(state,new_state){
        Vue.set(state.active_storage_unit,'status',new_state);
    },
    setActiveStorageUnit(state,nve){
        let result = state.current.storage_units.find(item => item.nve === nve);
        if(!result){
            result = state.current.remaining.find(item => item.nve === nve);
        }
        Vue.set(state,'active_storage_unit',result);
    },
    resetActiveStorageUnit(state){
        Vue.set(state,'active_storage_unit',null);
    },
    reset(state){
        let current = {
            outbound_id: null,
            order_number: null,
            delivery_note_number: null,
            custom_field_1: null,
            client: {},
            storage_units: [],
        };
        Vue.set(state,'current',current);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
